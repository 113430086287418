<template>
  <ProductCellFieldProductBasic
    v-bind="$attrs"
    v-on="$listeners">
    <template #previewDialog="{ selectedColumnOrder, selectedRefFields, dialog, manageDialog, selectRefFields }">
      <ProductCellFieldProductReferenceAdding
        :items="selectedColumnOrder"
        :selected="selectedRefFields"
        :dialog="dialog"
        @manageDialog="manageDialog"
        @selectFields="selectRefFields" />
    </template>
  </ProductCellFieldProductBasic>
</template>
<script>
import ProductCellFieldProductBasic from '../ProductCellFieldProductBasic';
export default {
  name: 'ProductCellFieldProductReference',
  components: {
    ProductCellFieldProductBasic,
    ProductCellFieldProductReferenceAdding: () => import('./ProductCellFieldProductReferenceAdding'),
  },
};
</script>